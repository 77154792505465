/* eslint-disable */
import React, { PureComponent } from 'react';

import { Helmet } from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class Prices extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Prix et commissions - Flowfo')}
          {seo.description(
            'Tous les prix sont définis par le créateur, la gratuité est possible. Flowfo prend uniquement une commission sur les ventes de Flows payants ou les abonnements.',
          )}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW PRIX FRANCAIS */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Thu Sep 02 2021 09:04:14 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Prix FRANCAIS</title>
          <meta content="Prix FRANCAIS" property="og:title" />
          <meta content="Prix FRANCAIS" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.a64fa8d4c.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div className="wf-section">
            <div className="divdeslangages">
              <a href="https://flowfo.me/prices" className="linklanguage w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5f95ec5bf00d8b10e6b4166c_THREE%20FLAGS.png"
                  alt="Three flags"
                  className="drapeauxronds"
                />
                <p className="changelangue">
                  read in english
                  <br />
                </p>
              </a>
            </div>
            <div className="divdesprix">
              <div className="block-prices-2">
                <h1 className="webflowheading1">Prix</h1>
                <h2 className="webflowheading2">En Euro</h2>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone">
                      <img
                        src="https://uploads-ssl.webflow.com/5d26ffc41443e0eefacca149/5d26ffc41443e08c05cca17a_euro%20circle.svg"
                        alt=""
                        className="image-8"
                      />
                    </div>
                  </div>
                  <div className="text-zone-of-cartouche">
                    <h3 className="webflowheading3 red">Définis par le créateur</h3>
                    <p className="webflowparagraphe">
                      Tous les prix sont définis par le créateur propriétaire du Flow.
                      <br />
                      Flowfo prélève une commission quand une transaction payante est réalisée.
                      <br />
                      Les créateurs sont rémunérés chaque mois, en fonction des ventes réalisées sur leur Flow.
                      <br />
                    </p>
                  </div>
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone">
                      <img
                        src="https://uploads-ssl.webflow.com/5d26ffc41443e0eefacca149/5d26ffc41443e06574cca17c_vat%20circle.svg"
                        alt=""
                        className="image-8"
                      />
                    </div>
                  </div>
                  <div className="text-zone-of-cartouche">
                    <h3 className="webflowheading3 violet">Nous calculons la TVA</h3>
                    <p className="webflowparagraphe">
                      Nous définissons la TVA à appliquer et l'ajoutons automatiquement au prix de vente. <br />
                    </p>
                  </div>
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche-2">
                  <div>
                    <div className="icone-zone">
                      <img
                        src="https://uploads-ssl.webflow.com/5f81a7a240627d7c821c69f2/5f81a7a240627d594f1c6a0b_conseil.svg"
                        alt=""
                        className="image-8"
                      />
                    </div>
                  </div>
                  <div className="text-zone-of-cartouche">
                    <p className="webflowparagraphe">
                      <strong>
                        Aucun frais minimum ou caché.
                        <br />
                        Nous prélevons la commission seulement si le créateur réalise des ventes.
                        <br />
                        Un Flow gratuit ne coûte rien.
                      </strong>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="block-commission">
                <h2 className="webflowheading1">Commission</h2>
                <p className="webflowheading2">
                  Nous prenons
                  <br />
                </p>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <h3 className="webflowheading1 violet">10,9% + 0,30€</h3>
                <h2 className="webflowheading2 violet">par transaction</h2>
                <h3 className="webflowheading3">Frais de paiement inclus</h3>
                <p className="webflowparagraphe centeredtext">
                  Les frais du prestataire de paiement sont inclus dans notre commission.
                  <br />‍<br />
                  La rémunération du créateur peut faire l'objet d'une charge supplémentaire indépendante à Flowfo.{' '}
                  <br />
                  Cette charge dépend de la solution choisie pour le transfert (Paypal ou virement sepa).
                  <br />
                </p>
              </div>
            </div>
            <div className="divwithcontent">
              <div className="w-container">
                <h3 className="webflowheading3">Flow gratuit, payant, ou par abonnement ?</h3>
                <p className="webflowparagraphe">
                  Un <strong>Flow gratuit (FREE FLOW)</strong> est un dossier dont le contenu est accessible à tous
                  (visiteurs inclus).
                  <br />
                  Un Flow gratuit peut devenir payant (et inversement).
                  <br />
                  <br />
                  Un <strong>Flow payant (PAYING FLOW)</strong> est un dossier dont le contenu est accessible uniquement
                  aux utilisateurs ayant acheté un droit d'accès. Cet accès reste valable durant toute la vie du Flow.
                  <br />
                  <br />
                  Un <strong>Flow par abonnement (SUBSCRIPTION FLOW)</strong> est un dossier dont le contenu est
                  accessible uniquement aux abonnés du Flow (renouvellement mensuel). <br />‍<br />
                  <strong />
                </p>
                <h3 className="webflowheading3">A propos des abonnements</h3>
                <p className="webflowparagraphe">
                  Les abonnements aux Flows se cumulent : si un utilisateur s'abonne à plusieurs Flows, il paie Flow A +
                  Flow B + Flow C...
                  <strong>
                    ‍<br />
                  </strong>
                  Le renouvellement de l'abonnement est automatique, sur une base mensuelle qui démarre toujours le 1er
                  du mois (il n'y a pas de période glissante).
                  <strong>
                    <br />
                    L'accès au Flow étant instantané, la 1ère échéance est toujours payée immédiatement et ne fait
                    l'objet d'aucune remise (même si la fin du mois est proche).
                    <br />
                    Un abonné peut arrêter son abonnement n'importe quand.{' '}
                  </strong>
                  L'accès au Flow lui reste acquis jusqu'à la fin du mois en cours.
                  <strong>
                    <br />
                  </strong>
                </p>
                <h3 className="webflowheading3 red">A propos des prix</h3>
                <p className="webflowparagraphe">
                  Les prix des Flows ont un <strong>minimum de 2€ et un maximum de 50€ (hors TVA).</strong>
                  <br />
                  <strong />
                </p>
                <h3 className="webflowheading3 red">A propos de la TVA</h3>
                <p className="webflowparagraphe">
                  La TVA est automatiquement ajoutée au prix HT défini par le créateur. <br />
                  Son montant est défini par le pays de résidence de l'acheteur (l'adresse IP et l'origine du paiement
                  sont vérifiées). Flowfo reverse la TVA aux administrations concernées.
                  <br />
                </p>
                <h3 className="webflowheading3 violet">Paiement aux créateurs</h3>
                <p className="webflowparagraphe">
                  Les paiements aux créateurs (PAYOUT) sont déclenchés <strong>le 10 de chaque mois.</strong> La devise
                  source est <strong>l'Euro €.</strong>
                  <br />
                  La méthode du paiement est choisie par le créateur.
                  <br />
                  <strong>SEPA TRANSFER</strong> - pour les créateurs européens ayant un compte bancaire compatible.
                  <br />
                  <strong>PAYPAL</strong> - nous devons payer les créateurs via <em>des transactions commerciales </em>
                  qui peuvent générer des frais. L'option <em>Friends and family </em>n'est malheureusement pas
                  possible.
                  <br />
                  Pour plus de détails sur les frais appliqués par Paypal, visitez{' '}
                  <a href="http://www.paypal.com" target="_blank" className="webflowlink">
                    www.paypal.com
                  </a>
                  ‍
                </p>
                <h3 className="webflowheading3 violet">Qu'est-ce qu'une transaction ?</h3>
                <p className="webflowparagraphe">
                  L'achat d'un Flow payant, l'abonnement, le renouvellement d'un abonnement sont considérés comme des
                  transactions faisant l'objet d'une commission.
                  <br />
                  Si un utilisateur achète deux Flows payants + un abonnement lors d'une même commande, cela représente
                  trois transactions.
                  <br />
                  <strong>Les Flows gratuits (FREE FLOWS) ne font l'objet d'aucune commission.</strong>
                </p>
                <h3 className="webflowheading3 violet">Est-ce que la TVA affecte la rémunération du créateur ?</h3>
                <p className="webflowparagraphe">
                  Non. Flowfo assume tous les extra que le prestataire de paiement pourrait facturer sur une TVA élevée.
                  <br />
                  <strong>
                    La commission du créateur reste ainsi transparente et se base uniquement sur le prix hors taxes.
                  </strong>
                  <br />
                  Si un créateur définit un prix à 4€, il sera payé 4€ - 10,9% - 0,30€ = 3,26€ quelle que soit la TVA
                  facturée à l'acheteur.
                </p>
                <h3 className="webflowheading3 red">Mon numéro de CB est-il en sécurité ?</h3>
                <p className="webflowparagraphe">
                  Afin de rendre les abonnements possibles, notre prestataire de paiement (au standard PCI) garde les
                  coordonnées bancaires de chaque acheteur.
                  <br />
                  Flowfo ne stocke pas ces informations sur ses propres serveurs et n'a accès qu'à une version tronquée.
                  <br />
                  Tout utilisateur peut retirer ses infos via l'API disponible sur la page{' '}
                  <a href="https://flowfo.me/profile/settings" target="_blank" className="webflowlink">
                    https://flowfo.me/profile/settings
                  </a>
                  <br />
                  Ses éventuels abonnements seront alors stoppés en fin de mois.
                </p>
                <div className="divendblank" />
              </div>
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW PRIX FRANCAIS */}
        </div>
      </>
    );
  }
}

export default Prices;
